import { api, param2query } from "./api.service";

export const downloadRelatorioAuditoriaContas = (filtro) => {
  return api.get(
    'relatorio-auditoria-contas/download?' + param2query(filtro),
    { responseType: 'blob' }
  ).then(({ data }) => {
    const href = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'relatorio-auditoria-retrospectiva-contas.xlsx');
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link);
  })
};
